import { PERMISSIONS } from "../../data/permissions";

export const ValidateMenuItems = (userPermissions: any) => {
  const menuItems: any = [];

  const items = [
    {
      menutitle: "General",
      Items: [
        {
          path: "/dashboard",
          title: "Inicio",
          icon: <i className="fa fa-home side-menu__icon " />,
          type: "link",
          selected: false,
          active: false,
        },
      ],
    },
    {
      menutitle: "Estadísticas",
      Items: [
        {
          title: "Calculo de tasas",
          icon: <i className="fa fa-chart-line side-menu__icon " />,
          type: "sub",
          selected: false,
          active: false,
          children: [
            {
              path: `/analitics`,
              type: "link",
              active: false,
              selected: false,
              title: "Deserción y Graduación",
              permission: PERMISSIONS.readRate,
            },
            {
              path: `/`,
              type: "link",
              active: false,
              selected: false,
              title: "Indicadores y metas",
              permission: PERMISSIONS.readUser,
            },
            {
              path: `/non-graduates`,
              type: "link",
              active: false,
              selected: false,
              title: "Egresados no graduados",
              permission: PERMISSIONS.readUser,
            },
          ],
        },
      ],
    },
    {
      menutitle: "Monitoreo",
      Items: [
        {
          title: "Analisis de Factores",
          icon: <i className="fa fa-cogs side-menu__icon" />,
          type: "sub",
          selected: false,
          active: false,
          children: [
            {
              path: `/`,
              type: "link",
              active: false,
              selected: false,
              title: "Evaluacion de Factores de Desercion",
              permission: PERMISSIONS.readUser,
            },
            {
              path: `/`,
              type: "link",
              selected: false,
              active: false,
              title: "Reporte de Factores de Desercion",
              permission: PERMISSIONS.readUser,
            },
          ],
        },
        {
          title: "Seguimiento",
          icon: <i className="fa fa-tasks side-menu__icon" />,
          type: "sub",
          selected: false,
          active: false,
          children: [
            {
              path: `/`,
              type: "link",
              active: false,
              selected: false,
              title: "Gestíon de Estudiantes",
              permission: PERMISSIONS.readUser,
            },
            {
              path: `/application`,
              type: "link",
              selected: false,
              active: false,
              title: "Solicitudes de Reintegro",
              permission: PERMISSIONS.writeApplication,
            },
            {
              path: `/`,
              type: "link",
              selected: false,
              active: false,
              title: "Reporte de Reintegros y Reingresos",
              permission: PERMISSIONS.readUser,
            },
          ],
        },
      ],
    },
    {
      menutitle: "Administración",
      Items: [
        {
          path: `/administration/users`,
          title: "Usuarios",
          icon: <i className="fa fa-user side-menu__icon" />,
          type: "link",
          selected: false,
          active: false,
          permission: PERMISSIONS.readUser,
        },
        {
          title: "Plantillas SNIES",
          icon: <i className="fa fa-table side-menu__icon" />,
          type: "sub",
          selected: false,
          active: false,
          children: [
            {
              path: `/administration/import`,
              type: "link",
              active: false,
              selected: false,
              title: "Cargar Plantillas",
              permission: PERMISSIONS.writeTemplates,
            },
            {
              path: `/administration/list-students`,
              type: "link",
              selected: false,
              active: false,
              title: "Listar Plantillas",
              permission: PERMISSIONS.readTemplates,
            },
            {
              path: `/administration/modify-template`,
              type: "link",
              selected: false,
              active: false,
              title: "Actualización de datos",
              permission: PERMISSIONS.updateTemplates,
            },
            {
              path: `/administration/modify-download`,
              type: "link",
              selected: false,
              active: false,
              title: "Descargar actualizaciones",
              permission: PERMISSIONS.downloadUpdateTemplates,
            },
          ],
        },
        {
          path: `/administration/permissions`,
          title: "Roles y Permisos",
          icon: <i className="mdi mdi-account-key side-menu__icon" />,
          type: "link",
          selected: false,
          active: false,
          permission: PERMISSIONS.readUser,
        },
      ],
    },
  ];

  /* 
    JERARQUIA DE LOS ITEMS PARA VALIDAR
    .........menu: ¬
    ................item: ¬
    .....................subitem - link
    .....................subitem - sub: ¬
    ...................................item
  */

  // Recorremos los items del menu
  items.forEach((item: any) => {
    const childs: any = [];
    // recorremos los subitems de cada item del menu
    item.Items.forEach((child: any) => {
      // Identificamos que tipo de item es: link | sub
      if (child.type === "link") {
        // Si el item es tipo link verificamos que tenga permisos requeridos
        if (child.permission) {
          // si tiene permisos requeridos validamos que el usuario lo tenga y guardamos
          const isTrue = userPermissions.some((value: any) => value === child.permission);
          isTrue && childs.push(child);
        } else {
          // Si no tiene permisos requeridos guardamos el item tipo link sin necesidad validar que el usuario lo tenga
          childs.push(child);
        }
      } else {
        const childs2: any = [];
        // Recorremos los items de los subitems de cada item del menu
        child.children.forEach((child2: any) => {
          // Si el item es tipo link verificamos que tenga permisos requeridos
          if (child2.permission) {
            // si tiene permisos requeridos validamos que el usuario lo tenga y guardamos
            const isTrue = userPermissions.some((value: any) => value === child2.permission);
            isTrue && childs2.push(child2);
          } else {
            // Si no tiene permisos requeridos guardamos el item tipo link sin necesidad validar que el usuario lo tenga
            childs2.push(child2);
          }
        });

        if (childs2.length > 0) {
          childs.push({ ...child, children: childs2 });
        }
      }
    });

    if (childs.length > 0) {
      menuItems.push({ ...item, Items: childs });
    }
  });

  return menuItems;
};
